import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../../../components/Layout"
import HubspotForm from "react-hubspot-form"

export default function GetADemo() {
const styles = {
    fontContact: {
      color: "#fff",
      fontFamily: "DM Sans",
    },
    alignContent: {
      display: "flex",
      alignItems: "center",
    },
  }
  return (
    <Layout
      title="Watch Recording of Webinar on AI-Ready Support: Equipping Your Team for the GenAI Era"
      keywords="genai webinar recording"
      description="Watch the recording of the webinar on how to revolutionize your customer support by harnessing the power of Generative AI (GenAI)."
    >
      <Helmet>
        <script
          charSet="utf-8"
          src="https://js.hsforms.net/forms/shell.js"
        ></script>
        <link rel="canonical" href="https://irisagent.com/gen-ai-webinar/watch-webinar/" />
      </Helmet>

            <section
        style={{
          paddingTop: "40px",
        }}
      >
        <section
          id=" webinar-bg"
          className=" webinar-bg"
          style={{
            // backgroundImage: 'url("/img/resources/post 13.jpeg")',
            minHeight: "620px",
          }}
        >
          <div className=" webinar-container" style={styles.fontContact}>
            <img
              className="webinarButton"
              src="/img/WebinarButton.svg"
              alt=""
            />

            <h1 className="webinar-title" style={styles.fontContact}>
              AI-Ready Support:<br/>
              Equipping Your Team<br/> for the GenAI Era
            </h1>

            <div className="subtitle-web" style={{ display: "flex" }}>
              <img
                className="calendar"
                src="/img/calendar21Oct.svg"
                alt=""
                width={360}
              />
              <p className="time">Sep 17, 2024 10:00 AM PT</p>
            </div>
          </div>
          <div className="webinarHeader">
            <img
              src="/img/WebinarHeader.svg"
              alt=""
              className="webinarHeaderImg"
            />
          </div>
        </section>
      </section>


      <section className="inner-page" style={{ margin: "2% 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <h2>Watch Webinar Recording!</h2>
                <br />
                <p>
                  AI-Ready Support: Equipping Your Team for the GenAI Era
                  <br />
                  <br />

                  Watch the recording of webinar on how to revolutionize your customer support by harnessing the power of Generative AI (GenAI). In this webinar, industry experts will guide you through real-world examples and case studies, revealing how GenAI has driven over 40% deflection in customer inquiries. Discover actionable strategies to get your support team AI-ready, and gain practical tips to enhance your customer experience. Whether you’re just starting your GenAI journey or looking to optimize your existing processes, this webinar will provide the insights and tools you need to lead in the AI era.
                  <br />
                  <br />
                  <b>Key Takeaways:</b><br/>
        <ul>
<li>Understanding the evolution and future of customer support automation.</li>
<li>How to leverage GenAI to achieve higher deflection rates and improve customer satisfaction.</li>
<li>Practical advice on implementing and optimizing AI solutions in your support operations.</li>
<li>Real-world success stories and insights from industry experts.</li>
        </ul>

                </p>
                {/* <img
                  src="/img/webinar-bg-2.jpeg"
                  className="img-fluid"
                  alt="Schedule-demo"
                /> */}
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
                <HubspotForm
                  portalId="7494256"
                  formId="ef0d33b4-7913-4f68-bdb4-da366805701b"
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
